@import 'mixins';


::selection{
    // background-color: $main-orange;
    // color: white;
}

::-webkit-scrollbar{
    // display: none;
}
*{
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


html{
    scroll-behavior: smooth;
}

*{
    margin: 0px;
    padding: 0px;
    font-family: $robot;
}



.event-button{
    cursor: pointer;
}

.site-header{
    width: 100%;
    height: 140px;
    // background-color: red;
    @include flexColumn(flex-start);
    align-items: center;

    &__up-container{
        max-width: 1200px;
        width: 100%;
        height: 70px;
        // background-color: yellow;
        @include flexRow(space-between);
    }

    &__logo-block{
        // width: 200px;
        height: 100%;
        // background-color: pink;
        @include flexRow(flex-start);
        align-items: center;
        cursor: pointer;
    }
    
    &__logo{
        // background-color: yellow;
        // width: 200px;
        height: 50px;
    }

    &__cities-container{
        width: 250px;
        height: 100%;
        // background-color: pink;
        // @include flexColumn(center);
        @include flexRow(flex-start);
        align-items: center;
        position: relative;
        cursor: pointer;
        z-index: 10;
        &:hover{
            .site-header__city{
                text-decoration: underline;
            }

            .site-header__city-control{
                animation: none;
            }
            .site-header__cities-list{
                @include flexColumn(flex-start);
            }
        }
    }

    @keyframes cityControl {
        0%{margin-top: 3px}
        10%{margin-top: 10px}
        25%{margin-top: 3px}
        40%{margin-top: 10px}
        45%{margin-top: 3px}
        100%{margin-top: 3px}
    }

    &__city-control{
        // transition: 0.3s;
        // background-color: red;
        @include flexColumn(center);
        margin-left: 10px;
        margin-top: 3px;
        animation: 2s cityControl infinite;
    }

    &__city{
        // color: red;
        @include flexRow(flex-start);
        align-items: center;
        cursor: default;
        font-size: 24px;
        // font-weight: 300;
        strong{
            font-weight: 400;
        }
    }

    &__cities-list{
        background-color: white;
        // height: 200px;
        border-radius: 0px 0px 5px 5px;
        overflow-y: scroll;
        position: absolute;
        // display: none;
        top: 70px;
        @include flexColumn(flex-start);
        list-style: none;
        width: 100%;
        max-height: 300px;
        display: none;

        &::-webkit-scrollbar{
            width: 3px;
        }

        &::-webkit-scrollbar-thumb{
            background-color: $main;
        }
    }

    &__city-item{
        width: calc(100% - 20px);
        padding: 10px;
        // background-color: yellow;
        margin-bottom: 5px;
        cursor: pointer;
        &:hover{
            background-color: $main;
            .site-header__city-link{
                color: white;
            }
        }
    }

    &__city-link{
        text-decoration: none;
        color: black;
        // transition: 0.3s;
        &:hover{
            // text-decoration: underline;
            // color: $main;
            // background-color: $main;
        }
    }


    &__events-container{
        // background-color: pink;
        height: 100%;
        @include flexRow(flex-start);
        align-items: center;
    }

    &__header-phone{
        margin-right: 60px;
        text-decoration: none;
        color: black;
        font-size: 16px;
        font-weight: 500;
    }

    &__event-button{
        cursor: pointer;
        height: 50px;
        background-color: $main;
        padding: 0px 7px 0px 7px;
        border-radius: 20px 0px 20px 0px;
        text-decoration: none;
        color: white;
        @include flexColumn(center);
        align-items: center;
        text-align: center;
        // box-shadow: 0px 3px 4px $main;
        transition: 0.3s;
        &:hover{
            // box-shadow: none;
            box-shadow: 0px 0px 0px 2px $main inset;
            background-color: white;
            color: $main;
            // margin-top: 10px;
        }
    }

    &__down-container{
        width: 100%;
        height: 60px;
        margin-top: 10px;
        // background-color: pink;
        background-color: $main;
        @include flexRow(center);
    }

    .modal-header-button{
        display: none;
    }

    .modal-header-phone{
        display: none;
    }

    .modal-header-city{
        display: none;
    }

    &__mobile-button{
        display: none;
        position: relative;
        *{
            transition: 0.3s;
        }
        path{
            stroke: $main;
        }
    }

    &__mobile-button_active{
        path{
            position: absolute;
            &:nth-child(1){
                transform: rotate(45deg) translate(5px, -5px);
            }
            &:nth-child(2){
                opacity: 0;
            }
            &:nth-child(3){
                transform: rotate(-45deg) translate(-12px, -2px);
                // transform: translate(0px, -28px);

            }
        }
    }

    &__nav{
        max-width: 1200px;
        width: 100%;
        height: 100%;
    }

    &__menu{
        // background-color: plum;
        list-style: none;
        @include flexRow(space-between);
        height: 100%;
    }

    &__menu-item{
        // background-color: orange;
        // margin: 0px 15px 0px 15px;
        @include flexColumn(center);
    }

    &__menu-link{
        color: white;
        padding: 0px 7px 0px 7px;
        // background-color: aqua;
        height: 100%;
        @include flexColumn(center);
        font-weight: 450;
        text-decoration: none;
        transition: 0.3s;
        &:hover{
            background-color: white;
            color: black;
        }
    }
}

.main-section{
    width: 100%;
    height: 700px;
    // background-color: red;
    margin-top: 10px;
    @include flexRow(center);

    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        // background-image: url(./../src/img/main-section.png);
        // background-repeat: no-repeat;
        // background-position: 100%;
        position: relative;
    }
    
    &__img-block{
        width: 100%;
        height: 100%;
        // background-color: red;
        position: absolute;
        top: 0px;
        z-index: -1;
        @include flexRow(flex-end);
        align-items: center;
    }

    &__img{
        max-width: 500px;
        max-height: 500px;
    }

    &__header{
        margin-top: 30px;
        // background-color: pink;
        // width: 300px;
        width: calc(100% - 20px);
        padding: 0px 10px 0px 10px;
        font-size: 53px;
        color: $dark;
        
        strong{
            color: $main;
        }
    }

    &__text{
        margin-top: 25px;
        // background-color: pink;
        font-size: 24px;
    }

    &__pluses-container{
        // background-color: pink;
        margin-top: 50px;
        max-width: 600px;
        @include flexRow(flex-start);
        padding: 0px 0px 0px 10px;
        flex-wrap: wrap;
    }

    &__plus{
        // background-color: plum;
        margin: 10px;
        width: 180px;
        position: relative;
        font-size: 18px;

        &::before{
            content: ' ';
            position: absolute;
            top: 0px;
            left: -20px;
            margin-right: 10px;
            width: 7px;
            height: 7px;
            border: solid 4px $main;
            border-radius: 4px 0;
        }   
    }

    &__buttons-container{
        // background-color: plum;
        @include flexRow(flex-start);
    }

    @keyframes mainSectionBtn {
        0%{left: -50px;}
        50%{left: 400px;}
        100%{left: 400px;}
        // 100%{left: -50px;}
    }

    &__event-button{
        // animation: 1s mainSectionBtn infinite;
        // animation-duration: 3s;
        margin-top: 50px;
        cursor: pointer;
        width: 310px;
        height: 100px;
        background-color: $main;
        padding: 0px 7px 0px 7px;
        border-radius: 5px;
        text-decoration: none;
        color: white;
        @include flexColumn(center);
        align-items: center;
        text-align: center;
        // box-shadow: 0px 3px 4px $main;
        transition: 0.3s;
        font-size: 24px;
        position: relative;
        overflow: hidden;
        border-radius: 20px 0px 20px 0px;
        &:hover{
            box-shadow: none;
            // margin-top: 60px;
            background-color: white;
            color: $main;
            box-shadow: 0px 0px 0px 2px $main;
            &::before{
                animation: none;
            }
        }

        &::before{
            animation: 2s mainSectionBtn infinite;
            content: "";
            display: block;
            width: 30px;
            height: 200px;
            background-color: white;
            position: absolute;
            left: -50px;
            box-shadow: 3px 0px 3px 0px rgb(255, 255, 255);
            transform: rotate(15deg);
        }
    }
}

.cats-section{
    width: 100%;
    // height: 700px;
    // background-color: red;
    // outline: 1px solid #666;
    @include flexRow(center);
    padding: 30px 0px 30px 0px;
    
    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }
    
    &__header{
        // background-color: pink;
        width: calc(100% - 20px);
        padding: 0px 10px 0px 10px;
        text-align: center;
        font-size: 30px;
        font-weight: 500;
        color: $dark;
    }

    &__text{
        // background-color: pink;
        width: calc(100% - 20px);
        padding: 0px 10px 0px 10px;
        text-align: center;
        font-size: 18px;
        color: lighten($dark, 10%);
        margin-top: 10px;
    }
    
    &__cats-container{
        width: 100%;
        // height: 500px;
        // background-color: pink;
        margin-top: 30px;
        @include flexRow(center);
        flex-wrap: wrap;
    }
    
    &__cat{
        text-decoration: none;
        // max-width: 550px;
        width: 275px;
        // width: 100%;
        height: 265px;
        // min-height: 320px;
        // background-color: plum;
        @include flexColumn(flex-start);
        flex-wrap: wrap;
        overflow: hidden;
        padding: 10px;
        // background-color: yellow;
        margin: 15px;
        position: relative;
        cursor: pointer;
        &::before{
            transition: 0.3s;
            background-color: darken(white, 5%);
            border-radius: 50px 0px 50px 0px;
            position: absolute;
            display: block;
            content: "";
            width: 275px;
            height: 205px;
            // padding: 10px;
            // background-color: rgba(255, 0, 0, 0.247);
            // left: 0px;
            // top: 0px;
        }
        &:hover{
            &::before{
                background-color: $main;
            }
        }
    }

    &__img-block{
        z-index: 1;
        width: 275px;
        height: 205px;
        // background-color: orange;
        @include flexRow(center);
        align-items: center;
    }

    &__cat-img{
        max-width: 90%;
        max-height: 90%;
    }

    &__cat-header{
        // width: calc(100% - 30px);
        width: 100%;
        height: 50px;
        margin-top: 10px;
        // padding: 10px 10px 10px 20px;
        position: relative;
        color: $dark;
        // display: none;
        // background-color: salmon;
        text-align: center;
        font-size: 18px;
        
    }
}

.action-section{
    width: 100%;
    min-height: 200px;
    // background-color: red;
    @include flexRow(center);
    background-color: darken(white, 5%);
    padding: 40px 0px 0px 0px;
    
    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexRow(space-between);
        // flex-wrap: wrap;
    }

    &__content-block{
        // background-color: pink;
        max-width: 380px;
    }

    &__header{
        // background-color: plum;
        // margin: 20px;
        @include flexColumn(center);
        font-size: 24px;
        color: $dark;
        font-weight: 400;
    }

    &__text{
        // background-color: plum;
        @include flexRow(flex-start);
        margin-top: 20px;
    }

    &__phone{
        margin-top: 20px;
        width: 100%;
        // background-color: plum;
        display: block;
        font-size: 20px;
        font-weight: 500;
        text-decoration: none;
        color: $main;
        &:hover{
            text-decoration: underline;
        }
    }

    &__img-block{
        width: 320px;
        // height: 320px;
        // background-color: pink;
        @include flexColumn(flex-end);
        align-items: center;
        height: 200px;
    }

    &__img{
        max-width: 100%;
        max-height: 100%;
    }

    &__event-button{
        // margin: 20px;
        margin-top: 50px;
        cursor: pointer;
        width: 290px;
        height: 100px;
        background-color: $main;
        padding: 0px 7px 0px 7px;
        border-radius: 20px 0px 20px 0px;
        text-decoration: none;
        color: white;
        @include flexColumn(center);
        align-items: center;
        text-align: center;
        // box-shadow: 0px 3px 4px $main;
        transition: 0.3s;
        font-size: 24px;
        position: relative;
        // overflow: hidden;
        &:hover{
            box-shadow: 0px 0px 0px 2px $main inset;
            background-color: white;
            color: $main;
            // margin-top: 60px;
        }
    }
}

.brands-section{
    width: 100%;
    // height: 700px;
    // background-color: red;
    // outline: 1px solid #666;
    @include flexRow(center);
    padding: 30px 0px 30px 0px;
    
    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }
    
    &__header{
        // background-color: pink;
        width: calc(100% - 20px);
        padding: 0px 10px 0px 10px;
        text-align: center;
        font-size: 30px;
        font-weight: 500;
        color: $dark;
    }

    &__text{
        // background-color: pink;
        width: calc(100% - 20px);
        padding: 0px 10px 0px 10px;
        text-align: center;
        font-size: 18px;
        color: lighten($dark, 10%);
        margin-top: 10px;
    }
    
    &__brands-container{
        width: 100%;
        // height: 500px;
        // background-color: pink;
        margin-top: 30px;
        @include flexRow(center);
        flex-wrap: wrap;
    }
    
    &__brand{
        margin: 10px;
        width: 168px;
        height: 108px;
        // background-color: plum;
        border: 1px solid $main;
        @include flexColumn(center);
        align-items: center;
        text-decoration: none;
        color: $dark;
        transition: 0.3s;
        text-align: center;
        cursor: pointer;
        &:hover{
            background-color: $main;
            color: white;
            // font-weight: 500;
        }
    }
}

.features-section{
    width: 100%;
    // height: 400px;
    // background-color: red;
    @include flexRow(center);
    padding: 40px 0px;
    // margin-top: 100px;

    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__header{
        // background-color: pink;
        width: calc(100% - 20px);
        padding: 0px 10px 0px 10px;
        text-align: center;
        font-weight: 500;
        font-size: 30px;
        color: $dark;
    }

    &__features-container{
        width: 100%;
        // height: 320px;
        // background-color: pink;
        margin-top: 30px;
        @include flexRow(center);
        flex-wrap: wrap;
    }

    &__feature{
        margin: 20px;
        width: 300px;
        min-height: 230px;
        padding: 30px;
        border-radius: 50px 0px 50px 0px;
        // background-color: plum;
        @include flexColumn(flex-start);
        background-color: darken(white, 5%);
    }

    &__img-block{
        // background-color: aqua;
        height: 55px;
    }

    &__img{
        width: 50px;
        height: 50px;
    }

    &__feature-header{
        color: $dark;
        // background-color: aqua;
        margin-top: 20px;
        font-size: 24px;
        min-height: 60px;
        font-weight: 500;
    }

    &__feature-text{
        color: $dark;
        margin-top: 10px;
        // background-color: aqua;
    }
}

.sklad-section{
    width: 100%;
    height: 625px;
    // background-color: red;
    @include flexRow(center);
    background: url(./../src/img/sklad.jpg) calc(50% - 600px) top/1000px no-repeat;
    position: relative;
    overflow: hidden;

    &::before{
        display: block;
        content: "";
        position: absolute;
        left: 50%;
        bottom: 0;
        width: 1000px;
        height: 575px;
        margin: 0 0 0 340px;
        background: #f3f3f5;
        border-radius: 180px 0;
        transform: skew(-20deg, 0);
        z-index: -1;
    }

    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
        align-items: flex-end;
    }

    &__subcontainer{
        max-width: 700px;
        width: 100%;
        height: 100%;
        // background-color: plum;
        @include flexColumn(flex-start);
    }
    
    &__header{
        margin-top: 100px;
        max-width: 700px;
        // background-color: pink;
        font-size: 58px;
        color: $main;
    }
    
    &__text{
        // background-color: pink;
        font-size: 38px;
        max-width: 700px;
        width: 100%;
        margin-top: 20px;
    }

    &__button{
        width: 300px;
        height: 60px;
        background-color: $main;
        padding: 10px;
        margin-top: 20px;
        border-radius: 20px 0px 20px 0px;
        text-decoration: none;
        color: white;
        @include flexColumn(center);
        text-align: center;
        font-size: 20px;
        cursor: pointer;
        transition: 0.3s;
        &:hover{
            background-color: white;
            color: $main;
            box-shadow: 0px 0px 0px 2px $main inset;
        }
        // float: left;
    }
}

.master-section{
    width: 100%;
    height: 500px;
    // background-color: red;
    @include flexRow(center);
    // background-color: darken(white, 5%);
    // padding: 40px 0px 0px 0px;
    margin-top: 100px;
    position: relative;
    &::before{
        width: 100%;
        height: 370px;
        top: 130px;
        position: absolute;
        content: "";
        display: block;
        background-color: darken(white, 5%);
        z-index: -1;
    }
    
    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexRow(center);
        // flex-wrap: wrap;
    }

    &__img-block{
        width: 420px;
        // height: 320px;
        // background-color: pink;
        @include flexColumn(flex-end);
        align-items: center;
        height: 500px;
    }

    &__img{
        max-width: 100%;
        max-height: 100%;
    }

    &__content-block{
        // background-color: pink;
        max-width: 380px;
        // @include flexColumn(flex-end);
        margin-left: 60px;
    }

    &__header{
        // background-color: plum;
        // margin: 20px;
        @include flexColumn(center);
        font-size: 24px;
        color: $dark;
        font-weight: 400;
        margin-top: 160px;
    }

    &__text{
        // background-color: plum;
        @include flexRow(flex-start);
        margin-top: 20px;
    }

    &__accent-text{
        color: $main;
        font-weight: 500;
    }

    &__phone{
        margin-top: 20px;
        width: 100%;
        // background-color: plum;
        display: block;
        font-size: 20px;
        font-weight: 500;
        text-decoration: none;
        color: $main;
        &:hover{
            text-decoration: underline;
        }
    }

    &__event-button{
        // margin: 20px;
        margin-top: 50px;
        cursor: pointer;
        width: 290px;
        height: 100px;
        background-color: $main;
        padding: 0px 7px 0px 7px;
        border-radius: 20px 0px 20px 0px;
        text-decoration: none;
        color: white;
        @include flexColumn(center);
        align-items: center;
        text-align: center;
        // box-shadow: 0px 3px 4px $main;
        transition: 0.3s;
        font-size: 24px;
        position: relative;
        // overflow: hidden;
        &:hover{
            // box-shadow: 0px 0px 2px 0px $main;
            background-color: white;
            color: $main;
            box-shadow: 0px 0px 0px 2px $main inset;
            // margin-top: 60px;
        }
    }
}

.steps-section{
    margin-top: 100px;
    width: 100%;
    // height: 500px;
    // background-color: red;
    @include flexRow(center);
    padding: 40px 0px;

    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__header{
        // background-color: pink;
        width: calc(100% - 20px);
        padding: 0px 10px 0px 10px;
        text-align: center;
        font-weight: 500;
        font-size: 30px;
        color: $dark;
    }

    &__steps-container{
        width: 100%;
        // height: 400px;
        // background-color: pink;
        margin-top: 60px;
        @include flexRow(flex-start);
        flex-wrap: wrap;
    }

    &__step{
        width: 255px;
        height: 315px;
        // background-color: plum;
        @include flexColumn(flex-start);
    }

    &__step{
        &:nth-child(1){
            .steps-section__img-block{
                &::after{
                    content: "01";
                }
            }
        }
        &:nth-child(3){
            .steps-section__img-block{
                &::after{
                    content: "02";
                }
            }
        }
        &:nth-child(5){
            .steps-section__img-block{
                &::after{
                    content: "03";
                }
            }
        }
        &:nth-child(7){
            .steps-section__img-block{
                &::after{
                    content: "04";
                }
            }
        }
        &:nth-child(9){
            .steps-section__img-block{
                &::after{
                    content: "05";
                }
            }
        }
        &:nth-child(11){
            .steps-section__img-block{
                &::after{
                    content: "06";
                }
            }
        }
    }

    &__img-block{
        width: 255px;
        height: 180px;
        // background-color: aqua;
        position: relative;
        @include flexRow(center);
        align-items: center;

        svg{
            z-index: 1;
            max-width: 60%;
            max-height: 60%;
            path{
                fill: $main;
            }
        }

        &::before{
            content: "";
            display: block;
            width: 180px;
            height: 180px;
            position: absolute;
            // background-color: darkcyan;
            background-color: darken(white, 5%);
            border-radius: 20px 0px 20px 0px;
            // @include flexColumn(center);
            // align-items: center;
        }

        &::after{
            content: "";
            display: block;
            width: 30px;
            height: 30px;
            background-color: $main;
            position: absolute;
            left: calc(100% - 20px);
            top: 0px;
            border-radius: 10px 0px 10px 0px;
            color: white;
            text-align: center;
            @include flexColumn(center);
            font-weight: 600;
        }
    }

    &__step-header{
        font-size: 19px;
        margin-top: 10px;
        text-align: center;
        // background-color: aqua;
        font-weight: 500;
        color: $dark;
    }

    &__step-text{
        margin-top: 10px;
        // background-color: aqua;
        color: $dark;
    }

    &__gap{
        width: 217px;
        height: 315px;
        // background-color: orange;
        @include flexRow(center);
        align-items: flex-start;
    }

    &__gap-img{
        margin-top: 100px;
    }

    &__gap-line{
        // background-color: aqua;
        width: 100%;
        @include flexRow(center);
        align-items: center;
    }
}

.certificate-section{
    // margin-top: 100px;
    width: 100%;
    // height: 500px;
    // background-color: red;
    @include flexRow(center);
    background-color: darken(white, 5%);
    padding: 40px 0px;

    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__header{
        // background-color: pink;
        width: calc(100% - 20px);
        padding: 0px 10px 0px 10px;
        text-align: center;
        font-weight: 500;
        font-size: 30px;
        color: $dark;
    }

    &__certificates-container{
        width: 100%;
        // height: 400px;
        // background-color: pink;
        margin-top: 30px;
        @include flexRow(center);
        flex-wrap: wrap;
    }

    &__certificate{
        width: 160px;
        height: 210px;
        margin: 10px;
        // background-color: plum;
        @include flexColumn(flex-start);
        border: 2px solid $main;
        border-radius: 10px;
    }
}

.rating-section{
    width: 100%;
    // height: 500px;
    // background-color: red;
    @include flexRow(center);
    padding: 40px 0px;
    // margin-top: 50px;

    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__stat-block{
        width: 100%;
        // height: 300px;
        // background-color: pink;
        @include flexRow(center);
        flex-wrap: wrap;
    }

    &__rating-block{
        max-width: 350px;
        padding: 10px;
        width: 100%;
        height: 280px;
        // background-color: plum;
        box-shadow:  0px 0px 0px 1px $main;
        border-radius: 30px 0px 30px 0px;
        @include flexColumn(center);
        align-items: center;
        margin: 35px;
    }

    &__rating{
        // background-color: orange;
        @include flexRow(center);
        align-items: center;
    }

    &__rating-text{
        font-size: 35px;
    }

    &__rating-value{
        margin-left: 10px;
        font-size: 45px;
        color: $main;
    }

    &__rating-container{
        width: 300px;
        height: 40px;
        // background-color: orange;
        margin-top: 10px;
        @include flexRow(center);
        align-items: baseline;
    }
    
    &__rating-star-container{
        width: 40px;
        height: 40px;
        // background-color: aqua;
        margin: 0px 3px 0px 3px;
    }


    &__rating-star{
        position: relative;
        // overflow: hidden;
        &::after{
            content: "";
            display: block;
            width: 40px;
            height: 40px;
            // background-color: darkcyan;
            position: absolute;
            top: 0px;
        }
    }

    &__rating-base-text{
        font-size: 20px;
        margin-top: 10px;
        // background-color: orange;
        text-align: center;
        color: $dark;
    }

    &__statistics-block{
        width: 270px;
        height: 250px;
        // background-color: plum;
        margin: 35px;
        padding: 10px;
        @include flexColumn(flex-start);
    }

    &__statistics-line{
        width: 270px;
        height: 30px;
        // background-color: orange;
        margin-top: 10px;
        @include flexRow(flex-start);
        align-items: center;

        &:nth-child(1){
            .rating-section__statistics-mark-line{
                &::after{
                    width: 100%;
                }
            }
        }
        &:nth-child(2){
            .rating-section__statistics-mark-line{
                &::after{
                    width: 30%;
                }
            }
        }
        &:nth-child(3){
            .rating-section__statistics-mark-line{
                &::after{
                    width: 0%;
                }
            }
        }
        &:nth-child(4){
            .rating-section__statistics-mark-line{
                &::after{
                    width: 0%;
                }
            }
        }
        &:nth-child(5){
            .rating-section__statistics-mark-line{
                &::after{
                    width: 0%;
                }
            }
        }
    }

    &__statistics-mark{
        font-size: 24px;
        width: 25px;
        height: 100%;
        // background-color: aqua;
        @include flexColumn(center);
    }

    &__statistics-mark-line{
        width: 245px;
        height: 15px;
        background-color: rgb(228, 228, 228);
        border-radius: 15px;
        position: relative;
        overflow: hidden;

        &::after{
            content: "";
            display: block;
            // width: 100%;
            height: 100%;
            background-color: $main;
        }
    }

    &__recommended-block{
        width: 300px;
        height: 280px;
        // background-color: plum;
        margin: 35px;
        padding: 10px;
        @include flexColumn(center);
    }

    &__recommended-text{
        // background-color: orange;
        @include flexRow(flex-start);
        align-items: center;
    }

    &__users-count{
        color: $main;
        font-size: 65px;
    }

    &__recommended-users-text{
        font-size: 23px;
        color: $dark;
        margin-left: 10px;
    }

    &__event-button{
        // margin: 20px;
        margin-top: 20px;
        cursor: pointer;
        width: 290px;
        height: 100px;
        background-color: $main;
        padding: 0px 7px 0px 7px;
        border-radius: 20px 0px 20px 0px;
        text-decoration: none;
        color: white;
        @include flexColumn(center);
        align-items: center;
        text-align: center;
        // box-shadow: 0px 3px 4px $main;
        transition: 0.3s;
        font-size: 24px;
        position: relative;
        // overflow: hidden;
        &:hover{
            box-shadow: 0px 0px 0px 2px $main inset;
            background-color: white;
            color: $main;
            // margin-top: 60px;
            &::before{
                animation: none;
            }
        }

        &::before{
            animation: 2s mainSectionBtn infinite;
            content: "";
            display: block;
            width: 30px;
            height: 120px;
            background-color: white;
            position: absolute;
            left: -50px;
            box-shadow: 3px 0px 3px 0px rgb(255, 255, 255);
            transform: rotate(15deg);
        }
    }
}

.text-section{
    width: 100%;
    // height: 500px;
    // background-color: red;
    @include flexRow(center);
    padding: 40px 0px;
    background-color: darken(white, 5%);

    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__header{
        // background-color: pink;
        width: calc(100% - 20px);
        padding: 0px 10px 0px 10px;
        text-align: center;
        font-weight: 500;
        font-size: 30px;
        color: $dark;
        // background-color: pink;
    }

    &__text{
        // background-color: pink;
        margin-top: 20px;
        line-height: 30px;
        text-align: center;
        font-weight: 300;
        font-size: 18px;
    }
}

.sale-section{
    padding: 20px 0px 0px 0px;
    width: 100%;
    // min-height: 555px;
    // background-color: darken(white, 5%);
    background-color: white;
    @include flexRow(center);

    &__container{
        max-width: 1200px;
        width: 100%;
        // height: 100%;
        // background-color: yellow;
        @include flexRow(center);
    }

    &__img-block{
        width: 590px;
        height: 100%;
        // background-color: pink;
    }

    &__img{
        width: 590px;
        height: 100%;
    }

    &__time-container{
        max-width: 610px;
        width: 100%;
        height: 100%;
        // background-color: plum;
        @include flexColumn(flex-start);
    }

    &__timer-header{
        margin-top: 30px;
        font-size: 26px;
        font-weight: 500;
        color: $dark;
        // background-color: aqua;

        strong{
            font-weight: 500;
            color: $main;
        }
    }

    &__text{
        // background-color: aqua;
        margin-top: 50px;
        font-size: 18px;
        color: $dark;
    }

    &__timer-block{
        max-width: 430px;
        width: 100%;
        height: 150px;
        // background-color: aqua;
        margin-top: 20px;
        @include flexRow(center);
        align-items: center;
        flex-wrap: wrap;
    }

    &__timer-circle{
        width: 120px;
        height: 120px;
        // background-color: orange;
        background-color: white;
        border-radius: 100px;
        border: 4px solid $main;
        text-align: center;
        @include flexColumn(center);
        align-items: center;
        // margin: 5px;
    }

    &__timer-value{
        height: 50px;
        @include flexColumn(center);
        width: 100%;
        // background-color: aqua;
        font-size: 44px;
    }

    &__timer-name{
        font-size: 16px;
    }

    &__timer-separator{
        width: 20px;
        height: 100%;
        // background-color: peachpuff;
        font-size: 36px;
        font-weight: 600;
        text-align: center;
        @include flexColumn(center);
    }

    &__buttons-container{
        // background-color: pink;
        @include flexRow(flex-start);
    }

    &__event-button{
        margin-top: 50px;
        cursor: pointer;
        width: 350px;
        height: 100px;
        background-color: $main;
        padding: 0px 7px 0px 7px;
        border-radius: 20px 0px 20px 0px;
        text-decoration: none;
        color: white;
        @include flexColumn(center);
        align-items: center;
        text-align: center;
        transition: 0.3s;
        font-size: 24px;
        position: relative;
        overflow: hidden;
        &:hover{
            box-shadow: none;
            // margin-top: 60px;
            // transform: scale(1.1);
            background-color: white;
            color: $main;
            box-shadow: 0px 0px 0px 2px $main inset;
            // box-shadow: ;
            &::before{
                animation: none;
            }
        }

        &::before{
            animation: 2s mainSectionBtn infinite;
            content: "";
            display: block;
            width: 30px;
            height: 200px;
            background-color: white;
            position: absolute;
            left: -50px;
            box-shadow: 3px 0px 3px 0px rgb(255, 255, 255);
            transform: rotate(15deg);
        }
    }
}

.contacts-section{
    width: 100%;
    // height: 400px;
    // background-color: red;
    // padding: 40px 0px;
    // margin-top: 100px;

    &__header{
        // background-color: yellow;
        font-size: 32px;
        font-weight: 500;
        width: calc(100% - 20px);
        padding: 0px 10px;
        text-align: center;
        margin-top: 30px;
    }

    &__content-container{
        width: 100%;
        height: 600px;
        // background-color: yellow;
        // margin-top: 70px;
        @include flexRow(flex-start);
    }

    &__map-block{
        max-width: 1100px;
        width: 100%;
        height: 100%;
        // background-color: pink;
        border-radius: 0px 0px 200px 0px;
        overflow: hidden;
    }

    &__contacts-container{
        width: 300px;
        height: 100%;
        // background-color: pink;
        margin-left: 153px;
        @include flexColumn(flex-start);
    }

    &__contact-unit{
        // background-color: plum;
        margin-top: 30px;
        text-align: center;
    }

    &__contact-name{
        color: $dark;
    }

    &__contact-value{
        margin-top: 30px;
        font-size: 22px;
        font-weight: 500;
    }

    &__event-button{
        // margin-top: 30px;
        color: $main;
        font-weight: 500;
        font-size: 20px;
        &:hover{
            text-decoration: none;
        }
    }

    &__separator{
        width: 100%;
        height: 4px;
        background-color: rgb(219, 219, 219);
        margin-top: 30px;
    }
}

.site-footer{
    width: 100%;
    height: 100px;
    padding: 40px 0px;
    // background-color: red;
    @include flexRow(center);

    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexRow(space-between);
        align-items: center;
    }

    &__logo-block{
        width: 200px;
        height: 100%;
        // background-color: pink;
        @include flexColumn(center);
    }

    &__logo{
        width: 200px;
    }

    &__policy-button{
        font-size: 18px;
        color: $dark;
        &:hover{
            text-decoration: none;
        }
    }

    &__event-button{
        width: 250px;
        height: 70px;
        background-color: $main;
        border-radius: 20px 0px 20px 0px;
        color: white;
        text-decoration: none;
        text-align: center;
        @include flexColumn(center);
        align-items: center;
        font-size: 20px;
        transition: 0.3s;
        box-shadow: 0px 0px 0px 2px $main;
        &:hover{
            // box-shadow: 0px 0px 3px 0px $main;
            background-color: white;
            color: $main;
        }
    }
}



.error-block{
    width: 100%;
    height: 90vh;
    // background-color: red;
    @include flexRow(center);
    align-items: center;
    text-align: center;

    &__container{
        @include flexColumn(center);
        // background-color: yellow;
        height: 100%;
    }

    &__message{
        font-size: 36px;
    }

    &__link{
        color: $main;
        font-size: 36px;
    }
}

.modal-window{
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.37);
    backdrop-filter: blur(10px);
    position: absolute;
    z-index: 99999;
    @include flexRow(center);
    align-items: center;
    top: 0px;
    display: none;

    &__form{
        padding: 30px;
        width: 310px;
        // width: 500px;
        // height: 400px;
        background-color: white;
        border-radius: 25px 0px 25px 0px;
        @include flexColumn(flex-start);
        align-items: center;
        position: relative;
    }

    &__close-button{
        position: absolute;
        // background-color: red;
        width: 40px;
        height: 40px;
        @include flexColumn(center);
        align-items: center;
        left: calc(100% - 60px);
        bottom: calc(100% - 60px);
        border-radius: 40px;
        box-shadow: 0px 0px 0px 1px $dark;
        cursor: pointer;
        svg{
            transition: 0.3s;
            path{
                fill: $dark;
            }
        }
        &:hover{
            box-shadow: 0px 0px 0px 1px $main;
            svg{
                transform: rotate(90deg);
                path{
                    fill: $main;
                }
            }
        }
    }

    &__header{
        font-size: 20px;
        width: 200px;
    }

    &__user-input{
        margin-top: 20px;
        width: 250px;
        height: 40px;
        border: 1px solid rgb(168, 168, 168);
        border-radius: 5px;
        padding: 0px 0px 0px 5px;
        outline: none;
        font-size: 18px;
        font-weight: 300;

        &:focus{
            border-color: black;
        }
    }

    &__button{
        width: 250px;
        height: 40px;
        background-color: black;
        margin-top: 15px;
        color: white;
        border: none;
        outline: none;
        border-radius: 5px;
        cursor: pointer;
        transition: 0.3s;

        &:hover{
            background-color: $main;
        }
    }

    &__policy-text{
        width: 210px;
        margin-top: 10px;
        font-size: 12px;
        text-align: center;
        color: lightgrey;
    }
}

.up-button{
    width: 60px;
    height: 60px;
    border-radius: 100px 0px 0px 100px;
    background-color: white;
    box-shadow: 0px 0px 0px 2px black;
    // background-color: red;
    @include flexColumn(center);
    align-items: flex-start;
    // position: fixed;
    position: fixed;
    top: calc(95% - 65px);
    left: calc(100% - 60px);
    z-index: 100;
    display: none;

    .modal-button__svg-container{
        width: 65px;
        height: 65px;
        border-radius: 65px;
        // background-color: yellow;
        @include flexColumn(center);
        align-items: center;
    }
    svg{
        // background-color: white;
        // padding: 13px;
        // border-radius: 30px;
        width: 40px;
        height: 40px;
        path{
            fill: black;
        }
    }

    &:hover{
        .modal-button__svg-container{
            transform: scale(1.1);
        }
        svg{
            transform: scale(1.1);
        }
    }

    &:active{
        .modal-button__svg-container{
            transform: scale(1.1);
        }
        svg{
            transform: scale(1.1);
        }
    }
}

@keyframes modalCallAnim {
    0%{transform: rotate(0deg);}
    50%{transform: rotate(10deg);}
    100%{transform: rotate(0deg);}
}

.call-button{
    box-shadow: 0px 0px 0px 5px white;
    width: 60px;
    height: 60px;
    border-radius: 0px 100px 100px 0px;
    background-color: white;
    box-shadow: 0px 0px 0px 2px black;
    @include flexColumn(center);
    align-items: flex-end;
    // position: fixed;
    position: fixed;
    top: calc(95% - 65px);
    right: calc(100% - 60px);
    z-index: 100;
    display: none;
    
    .modal-button__svg-container{
        width: 65px;
        height: 65px;
        border-radius: 65px;
        // background-color: white;
        @include flexColumn(center);
        align-items: center;
    }
    
    svg{
        // display: none;
        animation: modalCallAnim 1s infinite;
        // background-color: white;
        // padding: 13px;
        // border-radius: 30px;
        fill: black;
        width: 40px;
        height: 40px;
        path{
            fill: black;
        }
    }

    &:hover{
        .modal-button__svg-container{
            transform: scale(1.1);
        }
        svg{
            animation-play-state: paused;
            transform: scale(1.1);
        }
    }

    &:active{
        .modal-button__svg-container{
            transform: scale(1.1);
        }
        svg{
            transform: scale(1.1);
        }
    }
}

@media (max-width: 1200px){
    .event-button{
        &::before{
            animation: none;
        }
    }

    .site-header{
        position: relative;

        &__up-container{
            // background-color: antiquewhite;
            @include flexRow(space-around);
        }

        &__mobile-button{
            @include flexColumn(center);
            // background-color: red;
        }

        .modal-header-phone{
            display: flex;
            color: $dark;
            padding: 10px 0px;
            // background-color: aqua;
            height: 100%;
            @include flexColumn(center);
            margin-right: 0px;
            font-weight: 450;
            text-decoration: none;
            width: 100%;
            text-align: center;
            font-size: 20px;
            transition: 0.3s;
            &:hover{
                background-color: white;
                color: black;
            }
        }

        .modal-header-button{
            display: flex;
            // box-shadow: 0px 0px 0px 2px $main;
            width: 200px;
            margin-top: 10px;
            color: white;
            background-color: $main;
            border-radius: 10px;
        }

        &__events-container{
            display: none;
        }

        &__cities-container{
            // display: none;
            // font-size: unset;
            display: none;
        }

        .modal-header-city{
            display: flex;
            // background-color: aqua;
            // text-align: center;
            text-align: center;
            align-items: center;
            margin-bottom: 10px;
            @include flexRow(center);
        }

        &__cities-list{
            top: 30px;
            box-shadow: 0px 3px 0px 0px $main;
            // background-color: red;
        }

        &__down-container{
            margin-top: 70px;
            padding: 10px 0px;
            height: unset;
            position: absolute;
            background-color: white;
            box-shadow: 0px 3px 0px 0px $main;
            display: none;
            background-color: white;
            z-index: 5;
        }
        
        &__nav{
            @include flexRow(center);
        }
        
        &__menu{
            // background-color: darkcyan;
            width: 100%;
            @include flexColumn(center);
            align-items: center;
        }
        
        &__menu-item{
            // margin: 10px 0px 10px 0px;
            text-align: center;
        }

        &__menu-link{
            color: $main;
            padding: 10px 0px;
            font-size: 20px;
        }
    }

    .main-section{
        height: unset;
        &__container{
            background-image: none;
            justify-content: center;
        }

        &__header{
            text-align: center;
            // word-break: break-all;
            // word-break: keep-all;
            // word-break: normal;
            word-wrap: break-word;
        }

        &__text{
            text-align: center;
        }

        &__pluses-container{
            order: 1;
            width: unset;
            max-width: unset;
            // background-color: red;
            justify-content: center;
        }

        &__plus{
            width: 140px;
            // background-color: red;
            margin: 10px;
        }

        &__buttons-container{
            // background-color: pink;
            // @include fl
            // display: block;
            justify-content: center;
            order: 3;
        }

        &__event-button{
            width: 290px;
            
            &::before{
                animation: none;
            }
        }

        &__img-block{
            // max-width: 500px;
            width: 100%;
            @include flexRow(center);
            // background-color: red;
            position: relative;
            order: 2;
        }

        &__img{
            max-width: 90%;
            max-height: 90%;
        }
    }

    .cats-section{
        &__cat-header{
            &::before{
                left: -5px;
            }
        }
    }

    .action-section{
        // height: unset;
        padding: 40px 0px;
        &__header{
            width: calc(100% - 20px);
            padding: 0px 10px 0px 10px;
            text-align: center;
        }

        &__container{
            @include flexColumn(flex-start);
            align-items: center;
        }

        &__text{
            text-align: center;
        }

        &__phone{
            margin-top: 20px;
            text-align: center;
        }

    }

    .sklad-section{
        height: unset;
        background: none;
        // background-color: red;
        padding: 40px 0px;

        &::before{
            display: none;
        }

        &__subcontainer{
            align-items: center;
        }
        &__header{
            margin-top: 0px;
            width: calc(100% - 20px);
            padding: 0px 10px 0px 10px;
            text-align: center;
            font-size: 38px;
        }

        &__text{
            width: calc(100% - 20px);
            padding: 0px 10px 0px 10px;
            text-align: center;
            font-size: 32px;
        }
    }

    .master-section{
        background-color: darken(white, 5%);
        height: unset;
        margin-top: 40px;
        padding: 40px 0px;
        // pad
        &::before{
            display: none;
        }

        &__img-block{
            display: none;
        }

        &__content-block{
            margin-left: 0px;
            @include flexColumn(flex-start);
            align-items: center;
        }

        &__header{
            margin-top: 0px;
            width: calc(100% - 20px);
            padding: 0px 10px 0px 10px;
            text-align: center;
        }

        &__text{
            width: calc(100% - 20px);
            padding: 0px 10px 0px 10px;
            text-align: center;
        }
    }

    .steps-section{
        &__steps-container{
            // align-items: center;
            justify-content: center;
        }

        &__gap{
            display: none;
        }

        &__gap-line{
            display: none;
        }
    }

    .rating-section{
        &__stat-block{
            justify-content: center;
        }

        &__rating-block{
            margin: 0px;
            width: 290px;
        }

        &__statistics-block{
            align-items: center;
            margin: 0px;
        }

        &__event-button{
            &::before{
                animation: none;
            }
        }
    }

    .sale-section{
        // height: unset;
        &__img-block{
            display: none;
        }

        &__timer-block{
            height: unset;
            // padding: 0px 0px 20px 0px;
        }

        &__timer-header{
            text-align: center;
        }

        &__text{
            text-align: center;
        }

        &__timer-separator{
            display: none;
        }

        &__buttons-container{
            justify-content: center;
        }

        &__event-button{
            width: 290px;
        }
    }

    .contacts-section{
        // display: none;
        @include flexRow(center);
        height: unset;
        margin-top: 50px;
        // background-color: red;
        
        &__content-container{
            height: unset;
            @include flexRow(center);
            flex-wrap: wrap;
            // background-color: yellow;
        }
        
        &__map-block{
            margin: 10px;
            width: 300px;
            height: 300px;
            border-radius: 500px;
            max-height: 300px;
        }

        &__contacts-container{
            margin: 10px;
            height: unset;
            // margin-left: 0px;
        }
    }

    .site-footer{
        @include flexRow(center);
        // display: none;
        height: unset;
        
        
        &__container{
            flex-wrap: wrap;
            @include flexRow(center);
        }

        &__logo-block,
        &__policy-button,
        &__event-button{
            margin: 10px;
        }
    }
}

